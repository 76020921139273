import React, { useRef, useEffect } from "react";

export default function HeroBackground() {
  const canvas = useRef(null);

  useEffect(() => {
    const c = canvas.current;
    const ctx = c.getContext("2d");

    function col(x, y, r, g, b) {
      ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
      ctx.fillRect(x, y, 1, 1);
    }
    let t = 0;
    function run() {
      for (let x = 0; x <= 35; x++) {
        for (let y = 0; y <= 35; y++) {
          col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
        }
      }
      t = t + 0.05;
      window.requestAnimationFrame(run);
    }
    run();
  }, []);
  return (
    <canvas
      ref={canvas}
      className="absolute hero-bg-sm md:hero-bg-md xl:hero-bg-lg"
      width="32"
      height="32"
      style={{
        // Set initial colour.
        background: `rgb(${RED_START}, ${GREEN_START}, ${BLUE_START})`,
      }}
    ></canvas>
  );
}

// Assuming we've got a high RED_START range, the comments on these knobs make sense.
// The higher this value is, the deeper and more vibrant the orange will be.
const RED_START = 256;
const RED_RANGE = 2;
// The higher this is, the more yellow it will be.
const GREEN_START = 128;
const GREEN_RANGE = 16;
// The higher this is, the more the colour tends towards pink.
const BLUE_START = 74;
const BLUE_RANGE = 24;

function R(x, y, t) {
  return Math.floor(
    RED_START + RED_RANGE * Math.cos((x * x - y * y) / 300 + t)
  );
}

function G(x, y, t) {
  return Math.floor(
    GREEN_START +
      GREEN_RANGE *
        Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300)
  );
}

function B(x, y, t) {
  return Math.floor(
    BLUE_START +
      BLUE_RANGE *
        Math.sin(
          5 * Math.sin(t / 9) +
            ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
        )
  );
}
