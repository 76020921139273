import React from "react";
import { graphql } from "gatsby";

import Hero from "../components/hero";
import Features from "../components/features/features";
import Layout from "../components/layout";
import Container from "../components/container";
import HeroBackground from "../components/hero/hero-background";
import Yc from "../../assets/images/investors/yc.svg";

const Index = ({ data }) => {
  // const { access, analyze, discover, sync } = data;

  return (
    <div className="bg-white">
      {/* Bit of a hack to do this separately from the main hero but it needs
        to live outside the container. */}
      <HeroBackground />
      <Layout title="Data exploration without SQL - Supersheets" homePage>
        <div
          style={{
            backgroundSize: "120px 30px",
            backgroundImage:
              // "linear-gradient(to right, #E4E7EB 1px, transparent 1px), linear-gradient(to bottom, #E4E7EB 1px, transparent 1px)",
              "linear-gradient(to right, #F5F7FA 1px, transparent 1px), linear-gradient(to bottom, #F5F7FA 1px, transparent 1px)",
          }}
        >
          <Container>
            <Hero />
            <Investors />
          </Container>
          <Features {...data} />
        </div>
      </Layout>
    </div>
  );
};

export default Index;

export const query = graphql`
  query FeatureImages {
    access: file(name: { eq: "access" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    analyze: file(name: { eq: "analyze" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    discover: file(name: { eq: "discover" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sync: file(name: { eq: "sync" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    collaborate: file(name: { eq: "collaborate" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

function Investors() {
  return (
    <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:pt-8 pb-20 lg:px-8">
      <p className="text-center text-base font-semibold uppercase text-gray-500 tracking-wider">
        Backed by Y Combinator
      </p>
      <div className="flex justify-center mt-8 ">
        <Yc className="w-16 h-16" />
      </div>
    </div>
  );
}
