import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { classNames } from "../../utils";

export default function Features(props) {
  const features = [
//    {
//      title: "Search",
//      text: (
//        <>
//          <Bold>Find the data you need.</Bold> Using natural search terms to
//          allow anyone to find the data they're looking for.
//          <br />
//          <br />
//          <Bold>Built for everyone.</Bold> With tagging and comments, you don't
//          have to be a expert to navigate the data warehouse.
//        </>
//      ),
//      image: props.discover.childImageSharp.gatsbyImageData,
//    },
    {
      title: "Explore",
      text: (
        <>
          <Bold>Take a look at your data.</Bold> Once you've found the data
          you're looking for, view it in a familiar spreadsheet interface.
          <br />
          <br />
          <Bold>Filter and sort with a click.</Bold> Just like with a filter in
          excel you can easily find the records you want and sort them.
        </>
      ),
      image: props.access.childImageSharp.gatsbyImageData,
    },
    {
      title: "Build",
      text: (
        <>
          <Bold>Create the views that you need.</Bold> Pivot tables let you
          transform data to create the data you're looking for.
          <br />
          <br />
          <Bold>View on top of views.</Bold> Supersheets lets you build on top
          of the data views that you create. This is similar to referencing a
          different workbook in excel, except far more robust and powerful.
        </>
      ),
      image: props.analyze.childImageSharp.gatsbyImageData,
    },
    {
      title: "Sync",
      text: (
        <>
          <Bold>Sync data into the tools you already use.</Bold> Supersheets
          integrates with your Google Sheets account and other SaaS apps, so
          that you can use the data where you need it day to day.
        </>
      ),
      image: props.sync.childImageSharp.gatsbyImageData,
    },
    {
      title: "Collaborate",
      text: (
        <>
          <Bold>Work with your data analsyts.</Bold> Everything you do in
          supersheets is converted in SQL queries that your analysts will find
          easy to understand.
        </>
      ),
      image: props.collaborate.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <div className="relative">
      <div className="divide-y divide-gray-100 border-t border-b border-gray-100">
        {features.map((f, i) => {
          const Feature = i % 2 === 0 ? LeftFeature : RightFeature;
          return <Feature key={i} {...props} {...f} />;
        })}
      </div>
    </div>
  );
}

function Bold({ children }) {
  return <span className="font-bold text-gray-800">{children}</span>;
}

function LeftFeature(props) {
  const { title, text, image } = props;
  return (
    <div className="relative flex items-center min-h-screen bg-gray-50">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4 lg:order-2">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <FeatureImage image={image} title={title} side="left" />
        </div>
      </div>
    </div>
  );
}

function RightFeature(props) {
  const { title, text, image } = props;
  return (
    <div className="relative flex items-center min-h-screen">
      <div className="grid space-y-12 lg:grid-cols-9">
        <div className="flex lg:col-span-4">
          <FeatureDescription title={title} text={text} />
        </div>
        <div className="flex lg:col-span-5">
          <FeatureImage image={image} title={title} side="right" />
        </div>
      </div>
    </div>
  );
}

function FeatureDescription({ title, text }) {
  return (
    <div className="max-w-lg m-auto px-8 md:px-10">
      <h2 className="text-4xl text-center lg:text-left lg:text-5xl font-extrabold tracking-tight text-gray-900 mb-8">
        {title}
      </h2>
      <p className="text-gray-600 font-normal text-xl text-center lg:text-left">
        {text}
      </p>
    </div>
  );
}

function FeatureImage({ image, side, title }) {
  return (
    <div className="flex-grow min-h-3/4">
      <FeatureBackground side={side}>
        <GatsbyImage
          alt={`${title} feature`}
          image={image}
          className={classNames(
            "block h-5/6 shadow-2xl drop-shadow-2xl ring-black ring-opacity-5 rounded-md lg:rounded-none",
            side === "left" ? "lg:rounded-r-3xl" : "lg:rounded-l-3xl"
          )}
        />
      </FeatureBackground>
    </div>
  );
}

function FeatureBackground(props) {
  const css = classNames(
    "flex items-center h-full w-full py-6 lg:py-16 px-8 lg:px-0",
    props.side === "left"
      ? "lg:pr-20 lg:rounded-r-3xl"
      : "lg:pl-20 lg:rounded-l-3xl"
  );
  return (
    <div
      className={css}
      style={{
        background:
          "linear-gradient(-45deg, #f9703e, #d64545, #647acb, #57ae5b)",
        backgroundSize: "400% 400%",
        animation: "gradient 15s ease infinite",
      }}
    >
      <div className="shadow rounded-3xl">{props.children}</div>
    </div>
  );
}
